import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WalletService } from '@x/ecommerce/domain-client';
import { BehaviorSubject, firstValueFrom } from 'rxjs';

export interface WalletTransactionFormDialogData {
  walletId: number;
}

export interface WalletTransactionFormDialogResult {
  walletId: number;
  amount: number;
  description: string;
}

@Component({
  selector: 'x-wallet-transaction-form-dialog',
  templateUrl: './wallet-transaction-form-dialog.component.html',
  styleUrls: ['./wallet-transaction-form-dialog.component.scss'],
})
export class WalletTransactionFormDialogComponent implements OnInit {
  loading$ = new BehaviorSubject(false);

  currency: string;

  formGroup = new UntypedFormGroup({
    isCredit: new UntypedFormControl(),
    amount: new UntypedFormControl(null, [Validators.required, Validators.min(1)]),
    description: new UntypedFormControl(null, [Validators.required, Validators.minLength(10)]),
  });

  constructor(
    private dialogRef: MatDialogRef<
      WalletTransactionFormDialogComponent,
      WalletTransactionFormDialogResult
    >,
    @Inject(MAT_DIALOG_DATA)
    public data: WalletTransactionFormDialogData,
    private walletService: WalletService,
  ) {}

  async ngOnInit() {
    this.loading$.next(true);

    const wallet = await firstValueFrom(this.walletService.fetchById(this.data.walletId));
    this.currency = wallet.currency;

    this.loading$.next(false);
  }

  submit() {
    this.formGroup.updateValueAndValidity();
    if (this.formGroup.invalid) return;

    this.dialogRef.close({
      walletId: this.data.walletId,
      amount: this.formGroup.value.amount,
      description: this.formGroup.value.description,
    });
  }
}
